import React, { useState, useRef, useEffect } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql, StaticQuery } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"
import Grid from "../components/imageGrid/grid"

//locomotive scroll
//loco
import { LocomotiveScrollProvider } from "react-locomotive-scroll"
import { useLocomotiveScroll } from "react-locomotive-scroll"

export default function PersonalPage({ data }) {
  const personal = data.personal.nodes
  const personalEdges = data.personal.edges
  //styles
  const LocoScrollStyles = styled.div`
    position: relative;
    z-index: 100;
  `

  //locomotive scroll

  return (
    <>
      <Seo title="Blackpower Barbie" />

      <Grid personal={personal} personalEdges={personalEdges} />
    </>
  )
}

export const query = graphql`
  query PersonalQuery {
    personal: allSanityPersonal(sort: { fields: order, order: ASC }) {
      nodes {
        name
        slug {
          current
        }
        description
        image {
          asset {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`
